import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Layout from "../../components/layout";
//import Markdown from "react-markdown";
import ReactMarkdown from "react-markdown";
import Image from "~/components/image";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";

export const query = graphql`
	query ArticleQuery($slug: String!) {
		strapiArticle(slug: { eq: $slug }) {
			strapiId
			title
			description
			content
			published_at
			updated_at
			image {
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(aspectRatio: 2.52, layout: FULL_WIDTH)
					}
				}
			}
			category {
				name
			}
			author {
				username
				picture {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 50, height: 50)
						}
					}
				}
			}
		}
	}
`;

const Article = ({ data }) => {
	const article = data.strapiArticle;
	const seo = {
		metaTitle: article.title,
		metaDescription: article.description,
		shareImage: article.image,
		article: true,
	};

	return (
		<Layout>
			<GatsbySeo
				title={article.title}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// ]}
			/>
			{article.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={article.image}
						alt={`Picture for ${article.title} article`}
						loading="eager"
					/>
				</div>
			)}

			<div className="">
				<div className="my-14">
					<p className="text-center font-semibold text-gray-600">
						{article.category.name} | Last updated:{" "}
						<Moment format="MMM Do YYYY">{article.updated_at}</Moment>
					</p>
					<h1 className="mb-4 mt-10 text-center">{article.title}</h1>
					<div>
						{article.image && (
							<div className="text-center">
								<Image
									className="rounded-full shadow-md transform"
									image={article.author.picture}
									alt={`Picture of ${article.author.username}`}
									loading="eager"
								/>
							</div>
						)}
					</div>
					<div className="text-center mb-14 mt-4">
						<p className=" font-light">
							By {article.author.username}
							{" • "}
							<Moment format="MMM Do YYYY">{article.published_at}</Moment>
						</p>
					</div>

					<ReactMarkdown className="prose m-auto mb-14" children={article.content} />

					{/* <Markdown source={article.content} escapeHtml={false} /> */}

					{/* <hr className="" />

					<div className="">
						<div>
							{article.image && (
								<div className="">
									<Image
										className="rounded-lg shadow-md transform"
										image={article.author.picture}
										alt={`Picture of ${article.author.username}`}
										loading="eager"
									/>
								</div>
							)}
						</div>
						<div className="">
							<p className="">By {article.author.username}</p>
							<p className="uk-text-meta uk-margin-remove-top">
									<Moment format="MMM Do YYYY">{article.published_at}</Moment>
								</p>
						</div>
					</div> */}
				</div>
			</div>
		</Layout>
	);
};

export default Article;
